<template>
  <v-toolbar flat color="white">
    <slot />
    <slot name="select" v-if="!divHidden"/>
    <!-- <v-col cols="6" sm="4" md="5" v-if="!divHidden">
    </v-col> -->
    <v-spacer></v-spacer>
    <slot name="btnSelect"/>
    <v-col cols="12"  :md="!divHidden ? 3 : 8" v-if="this.configFilter.disableTextField !== true">
      <v-text-field
        append-icon="search"
        :label="labelTextField"
        single-line
        hide-details
        v-on:keyup.enter="$emit('search', search)"
        v-model="search"
        color="primary" />
      <!-- <BrSearchDataTable @search="buscar" /> -->
    </v-col>

    <v-col cols="12"  md="3" v-if="hasSelect">
      <v-select
        :items="itemsSelect"
        :label="labelSelects"
        single-line
        hide-details
        v-model="selectFilter"
        :return-object="true"></v-select>
    </v-col>
    <slot name="selectTypePagamentos" />

    <v-btn 
      @click="switchStatus"
      v-if="this.configFilter.activeSwitchStatus"
      tile
      outlined
      small
      :color="getColorSwitch(this.configFilter.isStatus)">
      {{ this.configFilter.switchStatusName }}
    </v-btn>

    <BrFilter :configFilter="configFilter" 
        :listaSelect="configFilter.listaSelect" 
        :getItens="configFilter.getItens" 
        :jsonData="configFilter.jsonData" 
        :addlFilter="addlFilter" 
        :disableLineFilter="disableLineFilter" 
      />
    <v-btn text icon @click="update()" :loading="loadingUpdate">
      <v-icon>update</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import Events from '@/core/service/events'
import { size } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'

export default {
  name: `BrToolBar`,
  components: {
    BrFilter: () => import('@/views/components/filter')
  },
  props: {
    divHidden:{
      type: Boolean,
      default: () => false
    },
    itemsSelect: {
      type: Array,
      default: () => []
    },
    disableLineFilter:{
      type: Boolean,
      default: true
    },
    labelSelects: {
      type: String,
      default: 'Selecione'
    },
    configFilter: {
      type: Object,
      default: () => {}
    },

    addlFilter: {
      type: Boolean,
      default: false
    },

    labelTextField: {
      type: String,
      default: 'Busca por nome:'
    }
  },

  watch: {
    selectFilter(val) {
      Events.$emit('toolbar::selectFilter', val)
    }
  },

  data: () => ({
    search: '',
    selectFilter: '',
    loadingUpdate: false
  }),

  computed: {
    ...mapGetters('filter', ['objFilter']),
    hasSelect() {
      return size(this.itemsSelect) ? !!1 : !!0
    },

    variables: () => variables,
  },

  methods: {
    ...mapActions('filter', ['clearObjFilter']),
    update () {
      this.loadingUpdate = true
      Events.$emit('loadingTable::activate')
      this.configFilter.getItens({
        page: 1,
        order: this.configFilter.order,
        pageSize: this.$store.getters.ItensPaginacao,
        filterDtStart: this.objFilter.filterDtStart,
        filterDtEnd: this.objFilter.filterDtEnd,
        _filter: this.objFilter._filter,
        _addlFilter: this.objFilter._addlFilter
      })
      .then(() => {
        Events.$emit('paginacao::clear')
      })
      .finally(() => {
        this.loadingUpdate = false
        Events.$emit('loadingTable::deactivate')
        Events.$emit('modalFilter::deactivate')
      })
    },

    switchStatus () {
      this.configFilter.switchStatus()
      this.update()
    },

    getColorSwitch (status) {
      switch (status) {
        case false:
          return variables.colorAccent
        case true:
          return variables.colorPrimary
      }
    }
  },
  beforeDestroy () {
    this.clearObjFilter()
  },
}
</script>